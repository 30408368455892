export default [
  {
    path: '/auth/login',
    name: 'auth-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      resource: 'Auth',
      action: 'Read',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      resource: 'Auth',
      action: 'Read',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/reset/:token',
    name: 'auth-reset',
    component: () => import('@/views/auth/Reset.vue'),
    meta: {
      resource: 'Auth',
      action: 'Read',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/reset/:country/:token',
    name: 'auth-reset-with-country',
    component: () => import('@/views/auth/Reset.vue'),
    meta: {
      resource: 'Auth',
      action: 'Read',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/welcome/:token',
    name: 'auth-welcome',
    component: () => import('@/views/auth/Welcome.vue'),
    meta: {
      resource: 'Auth',
      action: 'Read',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/welcome/:country/:token',
    name: 'auth-welcome-with-country',
    component: () => import('@/views/auth/Welcome.vue'),
    meta: {
      resource: 'Auth',
      action: 'Read',
      layout: 'full',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/auth/not-authorized',
    name: 'auth-not-authorized',
    component: () => import('@/views/auth/NotAuthorized.vue'),
    meta: {
      resource: 'Auth',
      action: 'Read',
      layout: 'full',
    },
  },
]
