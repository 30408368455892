export default [
  {
    path: '/the-hub/articles',
    name: 'articles',
    props: true,
    component: () => import('@/views/theHub/Articles.vue'),
    meta: {
      resource: 'TheHub.Articles',
      action: 'Read',
      pageTitle: 'Articles',
      breadcrumb: [
        {
          text: 'Articles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/the-hub/articles/:id',
    name: 'article-details',
    props: true,
    component: () => import('@/views/theHub/ViewArticle.vue'),
    meta: {
      resource: 'TheHub.Articles',
      action: 'Read',
      pageTitle: 'Article Details',
      breadcrumb: [
        {
          text: 'Articles',
          to: {name: 'articles'}
        },
        {
          text: 'Article Details',
          active: true,
        }
      ]
    }
  },
  {
    path: '/theHub/comments',
    name: 'comments',
    props: true,
    component: () => import('@/views/theHub/Comments.vue'),
    meta: {
      resource: 'TheHub.Comments',
      action: 'Read',
      pageTitle: 'Comments',
      breadcrumb: [
        {
          text: 'Comments',
          active: true,
        },
      ],
    },
  },
  {
    path: '/theHub/comments/:id',
    name: 'comment-details',
    props: true,
    component: () => import('@/views/theHub/ViewComment.vue'),
    meta: {
      resource: 'TheHub.Comments',
      action: 'Read',
      pageTitle: 'Comment Details',
      breadcrumb: [
        {
          text: 'Comments',
          to: {name: 'comments'}
        },
        {
          text: 'Comment Details',
          active: true,
        },
      ],
    },
  },
  {
    path: '/theHub/tags',
    name: 'tags',
    props: true,
    component: () => import('@/views/theHub/Tags.vue'),
    meta: {
      resource: 'TheHub.Tags',
      action: 'Read',
      pageTitle: 'Tags',
      breadcrumb: [
        {
          text: 'Tags',
          active: true,
        },
      ],
    },
  },
  {
    path: '/the-hub/tags/:id',
    name: 'tag-details',
    props: true,
    component: () => import('@/views/theHub/ViewTag.vue'),
    meta: {
      resource: 'TheHub.Tags',
      action: 'Read',
      pageTitle: 'Tag Details',
      breadcrumb: [
        {
          text: 'Tags',
          to: {name: 'tags'}
        },
        {
          text: 'Tag Details',
          active: true,
        }
      ]
    }
  },
]
